import { createRef } from "react"

const state = {
  sections: 9,
  pages: 8,
  zoom: 75,
  paragraphs: [
    {
      offset: 1.2,
      factor: 2.25,
      header: "Why Digital Transformation.",
      image: "/img/img1.jpg",
      logo: "",
      num: "01",
      aspect: 1.77,
      text: "The Covid-19 pandemic has rendered many of our old business practices obsolete. Therefore, digital transformation may no longer be a 'nice to have' strategy, but the very key to an organization's survival. With the right technology, companies can ensure that essential functions will still be carried out during the pandemic."
    },
    {
      offset: 2.1,
      factor: 2.5,
      header: "How it will help Your Business.",
      image: "/img/img2.jpg",
      logo: "",
      num: "02",
      aspect: 1.77,
      text:
        "When done right, a successful digital transformation effort makes your organization much more competitive, and better able to delight and keep customers. It can provide new distribution channels, new ways to reach customers, reduce costs, improve productivity, and practically any business objectives that not only to survive, but to prepare for the future of business landscape."
    },
    {
      offset: 3,
      factor: 2.25,
      header: "What it takes to Transform.",
      image: "/img/img3.jpg",
      logo: "",
      num: "03",
      aspect: 1.77,
      text:
        "Digital transformation can only happen through the careful orchestration of different organizational capabilities that serve the mission, vision, and business objectives of an organization. To embrace brand-new ways of doing things—like working with new technologies—your culture needs to be open to experimentation and failure. "
    },
    {
      offset: 4,
      factor: 2.0,
      header: "Identify your Objectives.",
      image: "/img/img4.jpg",
      logo: "",
      num: "04",
      aspect: 1.77,
      text:
        "Key desired transformation objectives often map to: Enhancing the digital customer and end-user experience to improve  loyalty, revenues, productivity and retention; and transforming business processes to reduce costs, improve productivity, integrate supply-chain partners and differentiate offerings."
    },
    {
      offset: 5.1,
      factor: 1.95,
      header: "How we can Help you.",
      image: "/img/img7.jpg",
      logo: "",
      num: "05",
      aspect: 1.77,
      text:
        "We help brands transform their teams and their internal and external processes for success in the digital future. With a team of creative, strategist, business and development specialists, we consistently strive to be at the forefront of new media technology. "
    },
    { 
      offset: 6.8, 
      factor: 1.75, 
      header: "", 
      image: "/img/img6.jpg", 
      logo: "/img/soty.png",
      num: "",
      aspect: 1.77, 
      text: 
        "Based out of Singapore and Bali, Indonesia, Fleava has become one of the best quality Digital Agency in Asia. Our focus has always been to create enjoyable, intuitive, engaging and remarkable experience for people — that's what sets us apart from everyone else." 
    }
  ],
  stripes: [
    { offset: 0, color: "#000", height: 25 },
    { offset: 6, color: "#000", height: 20 }
  ],
 
  top: createRef()
}

export default state
