import ReactDOM from "react-dom"
import React, { Suspense, useEffect, useRef, useMemo } from "react"
import { Canvas, Dom, useLoader, useFrame } from "react-three-fiber"
import { TextureLoader, LinearFilter } from "three"
import lerp from "lerp"
import Plane from "./components/Plane"
import { Block, useBlock } from "./blocks"
import state from "./store"
import "./styles.css"

function Startup() {
  const ref = useRef()
  useFrame(() => (ref.current.material.opacity = lerp(ref.current.material.opacity, 0, 0.025)))
  return <Plane ref={ref} color="#0e0e0f" position={[0, 0, 200]} scale={[100, 100, 1]} />
}

function Paragraph({ image, index, offset, factor, header, aspect, text, num, logo }) {
  const { contentMaxWidth: w, canvasWidth, margin, mobile } = useBlock()
  const size = aspect < 1 && !mobile ? 0.65 : 1
  const alignRight = (canvasWidth - w * size - margin) / 2
  const pixelWidth = w * state.zoom * size
  const left = !(index % 2)
  const multiplier = mobile ? 2 : 1.2
  return (
    <Block factor={factor} offset={offset}>
      <group position={[left ? -alignRight : alignRight, 0, 0]}>
        <Plane map={image} args={[1, 1, 32, 32]} shift={70} size={size} aspect={aspect} scale={[w * (size * multiplier), (w * (size * multiplier)) / aspect, 5]} frustumCulled={true} />
        <Dom
          style={{ width: pixelWidth / (mobile ? 1 : 2), textAlign: left ? "left" : "left" }}
          position={[ (mobile ?  ((-w * size) / 2) : (left ? (-w * size) / 5 : -2)), (-w * size) / 1.2 / aspect - (mobile ? 1.4 : 0.4), 1]}>
          <div tabIndex={index}>
            {text}<br />
            <img src={logo} alt={logo === "" ? "" : "Studio of the Year — Fleava Digital Agency"} className="soty" />  
          </div>
          
        </Dom>

          <Dom
            style={{ width: pixelWidth / (mobile ? 1 : 2), textAlign: left ? "left" : "left" }}
            position={[(mobile ?  ((-w * size) / 2) : (left || mobile ? (-w * size)  / 2.4 : (w * size)  / 50 - .5)) , (w * size) / aspect / 2 + (mobile ? 5 : 4.5), 1]}>
            <h2>{header}</h2>
          </Dom>

        <Block factor={mobile? .05 : 0.2}>
          <Dom
            style={{ width: pixelWidth / (mobile ? 2 : 2), textAlign: left ? "left" : "left" }}
            position={[   ((left && !mobile ? w / 3.5 : -w * .5) ) * size,  (mobile ? (w * size) / aspect + 5 : (w * size) / aspect / 1.1), -1]}>
            <h5>{num}</h5>
          </Dom>
        </Block>
      </group>
    </Block>
  )
}

function Cross() {
  const { contentMaxWidth: w, mobile } = useBlock()
  const ref = useRef()
  const { viewportHeight } = useBlock()
  useFrame(() => {
    const curTop = state.top.current
    const curY = ref.current.rotation.z
    const nextY = (curTop / ((state.pages - 1) * (viewportHeight / 10))) * Math.PI
    ref.current.rotation.z = lerp(curY, nextY, 0.1)
  })
  return (
    <group ref={ref} scale={(mobile ? [1.2, 1.2, 1.2] : [2, 2, 2])} position={[-w / (mobile ? 3 : 1.7), -w / 10 - 2.25, -1]}>
      <Plane scale={[1, 0.2, 0.2]} color="#5857E7" />
      <Plane scale={[0.2, 1, 0.2]} color="#5857E7" />
    </group>
  )
}

function Line() {
  const { mobile } = useBlock()
  const ref = useRef()
  const { viewportHeight } = useBlock()
  useFrame(() => {
    const curTop = state.top.current
    const curY = ref.current.rotation.z 
    const nextY = (curTop / ((state.pages - 1) * (viewportHeight / 4))) * Math.PI
    ref.current.rotation.z = lerp(curY, nextY, 0.1)
  })
  return (
    <group  ref={ref} scale={(mobile ? [1, 1, 1] : [2, 2, 2])} position={[0, 0, -1]}>
      <Plane opacity={1} scale={[3, 0.5, 0.5]} color="#000000" />
    </group>
  )
}



function Content() {
  const images = useLoader(
    TextureLoader,
    state.paragraphs.map(({ image }) => image)
  )
  useMemo(() => images.forEach(texture => (texture.minFilter = LinearFilter)), [images])
  const { contentMaxWidth: w, canvasWidth, canvasHeight, mobile } = useBlock()
  
  return (
    <>
      <Block factor={1} offset={0}>
        <Block factor={-1}>
          <Line />
        </Block>
        <Block factor={.5}>
          <Plane args={mobile ? [3, .5, 32, 32] : [8, 1, 32, 32]} shift={8} color={'#22222D'} rotation={[0, 0, 0]} position={[0, 0, 0]} />
        </Block>
        <Block factor={1}>
          <Dom size={w * 0.08} position={[-w / 1.5, -w / 50 + 3.25, -1]}><h1>When you've hit rock bottom,{!mobile ? <br /> : " "}there's nowhere to go {!mobile ? <br /> : " "}<strong>but up.</strong></h1></Dom>
        </Block>

        <Block factor={1.3}>
          <Dom position={mobile ? [w / 5.5, -w / 50 - 1.5, -1] : [w / 2.5, -w / 50 - 2, -1]}>
            <h6>Transform<br />to Digital<br />&mdash;</h6>
          </Dom>
        </Block>
        <Block factor={1.5}>
          <Block factor={-1.12}>
            <Cross />
          </Block>
          <Dom position={[-w / 5.5, -5, -1]}>
            <h3>If you weren’t entirely prepared for the pandemic, you’re not alone. The pandemic has affected companies and forced them to review their business strategies to overcome the crisis. The only way a business will be able to succeed in a post-pandemic era is to undergo a <u>digital transformation</u> that takes into account all the lifestyle, economic and business changes that Covid-19 has forced upon the world.<br /><br /><strong> We're here to help you.</strong></h3>
          </Dom>
        </Block>
      </Block>
      <Block factor={1.3} offset={5.7}>
        <Block factor={.4}>
          <Dom  position={[-w , -1, -1]}>
            <img src="/img/rock.png"  alt="Digital Transformation — Fleava Digital Agency" className="rock_s" />  
          </Dom>
        </Block>
        <Dom position={mobile ? [-w / 1.5, -1, -1] : [-w / 3.5, -1, -1]}>
            <h1>&ldquo;Now is the perfect{!mobile ? <br /> : " "}time to do a Digital{!mobile ? <br /> : " "}Transformation.&rdquo;<br /> <em>&mdash; Forbes.</em></h1>
        </Dom>
      </Block>
      {state.paragraphs.map((props, index) => (
        <Paragraph key={index} index={index} {...props} image={images[index]} />
      ))}
      {state.stripes.map(({ offset, color, height }, index) => (
        <Block key={index} factor={1.2} offset={offset}>
          <Plane args={[20, height, 32, 32]} shift={5} color={color} rotation={[0, 0, 0]} position={[0, 0, -10]} />
        </Block>
      ))}

      <Block factor={.2} offset={7}>
        <Block factor={1}>
          <Dom size={w * 0.08} position={[-w / (mobile ? 1.5 : 4), -canvasHeight + (mobile ? 1.3 : 2.3), 0]}>
            <h1 className="large"><a href="mailto:hello@fleava.com?subject=Let's Talk Digital Transformation">Let's Talk.</a></h1>
          </Dom>
        </Block>
      </Block>

      
      <Block factor={1.25} offset={8}>
        <Block factor={-.12}>
            <Cross />
        </Block>
        <Dom className="bottom-left" position={[-canvasWidth / 5, -canvasHeight / 2, 10]}>
          <div className="footer">
            <div className="col">
              <strong><a href="https://fleava.com/sg" title="Fleava — Singapore Digital Agency" target="_blank" rel="noopener noreferrer">Singapore</a></strong>
              160 Robinson Road, #14-04 Singapore Business Federation Centre - 068914, Singapore
            </div>
            <div className="col">
              <strong><a href="https://fleava.com/id" title="Fleava — Indonesia Digital Agency" target="_blank" rel="noopener noreferrer">Bali</a></strong>
              Jalan Merta Agung, No.25, Kerobokan Kelod, Badung, Bali - 80361, Indonesia
            </div>
            <div className="col copy">
              &copy; 2020 <a href="https://fleava.com" target="_blank" title="Fleava — Digital Agency" rel="noopener noreferrer">Fleava</a>.<br />
              <a href="https://www.facebook.com/fleava/" target="_blank" title="Fleava on Facebook" rel="noopener noreferrer">Fb.</a> &nbsp;
              <a href="https://www.instagram.com/fleava/" target="_blank" title="Fleava on Instagram" rel="noopener noreferrer">Ig.</a> &nbsp;
              <a href="https://twitter.com/fleava" target="_blank" title="Fleava on Twitter" rel="noopener noreferrer">Tw.</a> &nbsp;
              <a href="https://www.linkedin.com/company/fleava/" target="_blank" title="Fleava on LinkedIn" rel="noopener noreferrer">In.</a> &nbsp;
              <a href="https://www.behance.net/fleava" target="_blank" title="Fleava on Behance" rel="noopener noreferrer">Bē.</a>
            </div>

          </div>
          
        </Dom>
      </Block>
    </>
  )
}


function App() {
  const scrollArea = useRef()
  const onScroll = e => (state.top.current = e.target.scrollTop)
  useEffect(() => void onScroll({ target: scrollArea.current }), [])
  return (
    <>
      <Canvas className="canvas" concurrent pixelRatio={1} orthographic camera={{ zoom: state.zoom, position: [0, 0, 500] }}>
        <Suspense fallback={
          <Dom center className="loading" >
            Loading Transformation
            <div className="loading_items">
              <span className="font1">.</span>
              <span className="font2">.</span>
              <span className="font3">.</span>
              <span className="font4">.</span>
            </div>
          </Dom>
        }>
          <Content />
          <Startup />
        </Suspense>
      </Canvas>
      
      <div className="scrollArea" ref={scrollArea} onScroll={onScroll}>
        {new Array(state.sections).fill().map((_, index) => (
          <div key={index} id={"0" + index} style={{ height: `${(state.pages / state.sections) * 100}vh` }} />
        ))}
      </div>

      <a href="https://fleava.com/" target="_blank" rel="noopener noreferrer" className="logo" id="main-logo" title="Fleava — Digital Agency">
        <div className="logo-wrap" id="logo-wrap">
          <img src="/img/fleava-logo.svg" title="Fleava — Digital Transformation Agency" alt="Fleava — Digital Transformation Agency" height="20px" />
        </div>
      </a>
      {/* <div className="frame">
        
        <div id="fp-nav" className="right active">
          <div className="fp_overlay"></div>
          <ul className="frame__nav">
            <li><a className="frame__link" href="#00" children="intro"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">Introduction</div></li>
            <li><a className="frame__link" href="#01" children="01"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">Why Digital Transformation</div></li>
            <li><a className="frame__link" href="#02" children="02"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">How it will help your Business</div></li>
            <li><a className="frame__link" href="#03" children="03"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">What it takes to Transform</div></li>
            <li><a className="frame__link" href="#04" children="04"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">Identify your Objectives</div></li>
            <li><a className="frame__link" href="#05" children="05"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">How we can help you</div></li>
            <li><a className="frame__link" href="#07" children="06"><span></span><svg width="30" height="30"><circle cx="15" cy="15" r="11.5"></circle></svg></a><div className="fp-tooltip right">The time is Now</div></li>

          </ul>
        
        </div>
      </div> */}
    </>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))


